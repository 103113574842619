export const userExperienceProfileSelector = {
  FDR_DESK: {
    show_icon: true,
    show_banner: true
  },
  FDR_SA: {
    show_icon: true,
    show_banner: true
  }
};

export const HPBannerCloseModalMessages = {
  title: "Switch Homepage Profiles",
  description:
    'You can change and customize Homepage Profiles under <strong>"Preferences > Homepage"</strong>.'
};
